import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useInjection } from '@/ioc/ioc.react';
import { TAG_TYPES } from '@/ioc/types';

import { useAccount } from '@/features/common/account';
import { Permission, usePermissions } from '@/features/common/permissions';
import { type ITagUseCase, useTagEntities } from '@/features/common/tag';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

const useTagTableActionCellViewModel = (
  uuid: string,
  createdByUuid: string,
  name: string,
) => {
  const tagUseCase = useInjection<ITagUseCase>(TAG_TYPES.TagUseCase);
  const { data: tagList } = useTagEntities();
  const { account } = useAccount();
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('tag');
  const isLastTag = tagList.length === 1;
  const userUuid = account?.uuid;
  // permissions
  const { hasPermissions } = usePermissions();
  const canManageAllTags = hasPermissions(Permission.CanEditOtherTags);
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    if (canManageAllTags) {
      setIsVisible(true);
    } else {
      setIsVisible(createdByUuid === userUuid);
    }
  }, [canManageAllTags, userUuid, createdByUuid]);

  const onDelete = (): void => {
    setIsDeleteDialogVisible(true);
  };

  const onCloseDeleteDialog = (): void => {
    setIsDeleteDialogVisible(false);
  };

  const deleteDialog = async (): Promise<void> => {
    await tagUseCase.deleteTag(uuid);
    onCloseDeleteDialog();

    if (createdByUuid.length) {
      trackEvent(ANALYTICS_EVENTS.REMOVE_TAG, {
        tag_name: name,
      });
    }

    enqueueSnackbar({
      variant: 'success',
      message: t('deleteTag.toastSuccessTitle'),
      description: t('deleteTag.toastSuccessSubtitle'),
    });
  };

  return {
    isVisible,
    isDeleteDialogVisible,
    onDelete,
    onCloseDeleteDialog,
    deleteDialog,
    isLastTag,
  };
};

export default useTagTableActionCellViewModel;
