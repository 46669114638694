import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { firstValueFrom } from 'rxjs';

import { useInjection } from '@/ioc';
import { PAYMENT_DETAILS_TYPES } from '@/ioc/types';

import { IPaymentDetailsUseCase } from '../..';
import { IReceiptAdjustmentEntity } from '../../domain';
import { PaymentDetailsContext, PaymentFormValues } from '../contexts';

import { useReceipt } from '.';

type UsePaymentSummary = () => {
  receipt: ReturnType<typeof useReceipt>;
  billingCycle: 'daily' | 'monthly' | 'annually';
  onPromotionCodeApply: (code: string) => Promise<void>;
  onPromotionCodeRemove: () => void;
  promotionCode?: IReceiptAdjustmentEntity;
  promotionsAvailable: boolean;
};

export const usePaymentSummary: UsePaymentSummary = () => {
  const paymentDetailsUseCode = useInjection<IPaymentDetailsUseCase>(
    PAYMENT_DETAILS_TYPES.PaymentDetailsUseCase,
  );

  const [promotionCode, setPromotionCode] = useState<
    IReceiptAdjustmentEntity | undefined
  >();

  const { targetProduct, currentSubscription } = useContext(PaymentDetailsContext);

  const { setValue } = useFormContext<PaymentFormValues>();

  const promotionsAvailable =
    currentSubscription.planIsFree || !currentSubscription.promoCodeId;

  const reciept = useReceipt({
    promotionCode,
  });

  function onPromotionCodeRemove(): void {
    setPromotionCode(undefined);
    setValue('promotionCode', undefined);
  }

  async function onPromotionCodeApply(code: string): Promise<void> {
    const promotionCode = await firstValueFrom(
      paymentDetailsUseCode.getPromocode({
        code: code,
        plan: targetProduct.id,
        currency: reciept.data.currency,
      }),
    );
    setPromotionCode(promotionCode);
    setValue('promotionCode', promotionCode.id);
  }

  return {
    receipt: reciept,
    billingCycle: targetProduct.cycle,
    onPromotionCodeRemove,
    onPromotionCodeApply,
    promotionCode,
    promotionsAvailable,
  };
};
