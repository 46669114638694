import { FC } from 'react';
import { Box, Modal, Paper } from '@mui/material';

import CloseIcon from '@/assets/icons/close-round.svg?react';

import { LimitReached } from './components/LimitReached';
import { UpgradeAccount } from './components/UpgradeAccount';
import type { ErrorReason } from './types';

import styles from './styles.module.scss';

type ErrorModalProps = {
  open: boolean;
  reason: Nullable<ErrorReason>;
  onClose(): void;
};

const ErrorModalBody: FC<Pick<ErrorModalProps, 'reason'>> = ({ reason }) => {
  switch (reason) {
    case 'upgradeAccount':
      return <UpgradeAccount />;
    case 'limitReached':
      return <LimitReached />;
    default:
      return null;
  }
};

export const ErrorModal: FC<ErrorModalProps> = ({ open, reason, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} disableEnforceFocus>
      <Paper elevation={0} className={styles.paper}>
        <Box className={styles.closeIcon} onClick={onClose}>
          <CloseIcon />
        </Box>
        <ErrorModalBody reason={reason} />
      </Paper>
    </Modal>
  );
};
