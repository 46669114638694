import * as amplitude from '@amplitude/analytics-browser';
import { inject, injectable } from 'inversify';
import { combineLatest, distinctUntilChanged, filter, pairwise } from 'rxjs';

import { ACCOUNT_TYPES, AUTH_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import { IAccountEntity, IAccountRepository } from '@/features/common/account';
import { AuthStatus, IAuthRepository } from '@/features/common/auth';
import {
  IWorkspaceEntity,
  IWorkspaceRepository,
  SubscriptionPlan,
} from '@/features/common/workspace';

import { IAnalyticsRepository } from '../domain';

@injectable()
export class AnalyticsRepository implements IAnalyticsRepository {
  constructor(
    @inject(AUTH_TYPES.AuthRepository)
    private readonly authRepositorty: IAuthRepository,

    @inject(ACCOUNT_TYPES.AccountRepository)
    private readonly accountRepository: IAccountRepository,

    @inject(WORKSPACE_TYPES.WorkspaceRepository)
    private readonly workspaceRepository: IWorkspaceRepository,
  ) {
    this.init();
    this.listenSignOut();
    this.listenAccountToIdentifyUser();
    this.listenMetadataToIdentifyEvent();
  }

  private init(): void {
    amplitude.init(import.meta.env.REACT_APP_AMPLITUDE_API_KEY, {
      defaultTracking: {
        sessions: false,
        pageViews: false,
        formInteractions: false,
        fileDownloads: false,
      },
      appVersion: import.meta.env.REACT_APP_VERSION,
    });
  }

  private listenAccountToIdentifyUser(): void {
    this.accountRepository
      .getAccount()
      .pipe(
        filter((account) => !!account),
        distinctUntilChanged((prev, current) => prev.uuid === current.uuid),
      )
      .subscribe((account) => {
        amplitude.setUserId(account.uuid);
      });
  }

  private listenSignOut(): void {
    this.authRepositorty
      .getAuthStatus()
      .pipe(
        pairwise(),
        filter(
          ([previous, current]) =>
            previous === AuthStatus.Authorized && current === AuthStatus.Unauthorized,
        ),
      )
      .subscribe(() => {
        amplitude.reset();
      });
  }

  private listenMetadataToIdentifyEvent(): void {
    combineLatest({
      account: this.accountRepository.getAccount(),
      workspace: this.workspaceRepository.getCurrentWorkspace(),
    })
      .pipe(
        filter(({ account, workspace }) => !!account && !!workspace),
        distinctUntilChanged(
          (
            prev: { account: IAccountEntity; workspace: IWorkspaceEntity },
            current: { account: IAccountEntity; workspace: IWorkspaceEntity },
          ) => {
            return (
              prev.account.uuid === current.account.uuid &&
              prev.workspace.uuid === current.workspace.uuid
            );
          },
        ),
      )
      .subscribe(
        ({
          account,
          workspace,
        }: {
          account: IAccountEntity;
          workspace: IWorkspaceEntity;
        }) => {
          const identifyEvent = new amplitude.Identify();

          identifyEvent.set('plan_type', workspace.subscription.plan);
          identifyEvent.set('account_id', workspace.uuid);
          identifyEvent.set(
            'account_number_of_paid_users',
            workspace.billableMembersCount,
          );
          identifyEvent.set('account_number_of_users', workspace.membersCount);
          identifyEvent.set('account_role', workspace.membersCount);
          identifyEvent.set('sign_up_date', new Date(account.createdAt).getTime());
          identifyEvent.set('sign_up_provider', account.providers[0]);
          identifyEvent.set(
            'powerlead_community',
            workspace.subscription.plan === SubscriptionPlan.FreePlusMonthly,
          );
          identifyEvent.set('credits_used', account.currentCreditsUsed);
          identifyEvent.set('complete_onboarding', account.isUserSignUpFinished);
          amplitude.identify(identifyEvent);
        },
      );
  }

  public trackEvent(eventType: string, eventOptions?: Record<string, unknown>): void {
    amplitude.track(eventType, eventOptions);
  }
}
