import { inject, injectable } from 'inversify';
import { firstValueFrom, map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import type { IHttpClient } from '@/features/system/network';

import type { IWorkspaceApiService } from '../abstractions/WorkspaceApiService';
import type {
  ICreditDC,
  IStripePurchaseDC,
  IStripeSubscriptionDC,
  IWorkspaceDC,
} from '../dataContracts';

const workspaceEndpoints = {
  workspaces: '/api/v1/workspaces',
  switch: '/api/v1/workspaces/switch',
  updateSusbcription: (id: string): string => `/api/v1/workspaces/${id}/subscription`,
  createSubscription: '/api/v1/billing-service/stripe/init-subscription',
  getCredits: '/api/v1/billing-service/api/v2/wallets/my/credits',
};

@injectable()
export default class WorkspaceApiService implements IWorkspaceApiService {
  @inject(NETWORK_TYPES.HttpClient) private httpClient: IHttpClient;

  fetchWorkspace(): Observable<IWorkspaceDC[]> {
    return this.httpClient
      .get<IWorkspaceDC[]>(workspaceEndpoints.workspaces)
      .pipe(map(({ data }) => data));
  }

  updateWorkspace(account: Partial<IWorkspaceDC>): Observable<IWorkspaceDC> {
    return this.httpClient
      .patch<IWorkspaceDC>(workspaceEndpoints.workspaces, account)
      .pipe(map(({ data }) => data));
  }

  createSubscription(params: {
    label: string;
    promo_code?: string;
    is_canceled?: boolean;
    quantity?: number;
  }): Promise<IStripeSubscriptionDC> {
    return firstValueFrom(
      this.httpClient
        .post<IStripeSubscriptionDC>(workspaceEndpoints.createSubscription, params)
        .pipe(map(({ data }) => data)),
    );
  }

  async updateSubscription(params: {
    workspaceId: string;
    paid_members_count?: number;
    plan?: string;
    is_canceled?: boolean;
    billing_details_filled?: boolean;
  }): Promise<IStripePurchaseDC> {
    const { workspaceId: id, ...payload } = params;
    const { data } = await firstValueFrom(
      this.httpClient.patch<IStripePurchaseDC>(
        workspaceEndpoints.updateSusbcription(id),
        payload,
      ),
    );
    return data;
  }

  switchWorkspace(req: {
    uuid: string;
    action: 'accept' | 'reject';
  }): Observable<boolean> {
    return this.httpClient
      .post<boolean>(workspaceEndpoints.switch, req)
      .pipe(map(({ data }) => data));
  }

  getAllCredits(): Observable<{ items: ICreditDC[]; total: number }> {
    return this.httpClient
      .get<{
        items: ICreditDC[];
        total: number;
      }>(workspaceEndpoints.getCredits, {
        cachePolicy: 'cache-first',
        revalidateAfter: 10_000,
      })
      .pipe(map(({ data }) => data));
  }
}
