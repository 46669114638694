import { BroadcastChannel } from 'broadcast-channel';
import Dexie from 'dexie';
import { inject, injectable, multiInject, postConstruct } from 'inversify';
import {
  addRxPlugin,
  createRxDatabase,
  removeRxDatabase,
  RxCollection,
  RxCollectionCreator,
  RxState,
} from 'rxdb';
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration-schema';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBStatePlugin } from 'rxdb/plugins/state';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { BehaviorSubject, filter, Observable, share } from 'rxjs';

import { APP_LOGGER_TYPES, LEADER_ELECTION_TYPES } from '@/ioc/types';

import type { ILeaderElectionRepository } from '@/features/system/leaderElection';
import { IAppLogger } from '@/features/system/logger';

import { DbError } from '../domain/errors';
import { CollectionName, Database, DatabaseCollections } from '../types';

import { IDbCollectionCreator } from './DbCollectionCreator';
import { IDbStateCreator } from './DbStateCreator';

addRxPlugin(RxDBStatePlugin);
addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(RxDBCleanupPlugin);
addRxPlugin(RxDBLeaderElectionPlugin);

if (import.meta.env.DEV) {
  addRxPlugin(RxDBDevModePlugin);
}

export const DB_NAME = 'powerlead-rxdb';

export enum DbStatus {
  Removing = 'Removing',
  Removed = 'Removed',
  Destroyed = 'Destroyed',
  Initializing = 'Initializing',
  Ready = 'Ready',
}

export interface IDbManager {
  init(params?: { withMigration?: boolean }): Promise<void>;
  getDb(): Observable<Database>;
  clear(params?: { reloadOtherTabs?: boolean }): Promise<void>;
  getStatus(): Observable<DbStatus>;
}

enum DbMessage {
  ReloadPage = 'Reload page',
}

/**
 * TaskScheduler is a simple class that allows to run tasks in a sequence and only one at a time.
 */
class TaskScheduler {
  private readonly tasks: (() => Promise<unknown>)[] = [];
  private isRunning = false;

  public async runTask(task: () => Promise<unknown>): Promise<void> {
    this.tasks.push(task);
    await this.run();
  }

  private async run(): Promise<void> {
    if (this.isRunning) {
      return;
    }

    this.isRunning = true;

    while (this.tasks.length) {
      const task = this.tasks.shift();

      if (task) {
        await task();
      }
    }

    this.isRunning = false;
  }
}

@injectable()
export class DbManager implements IDbManager {
  @inject(LEADER_ELECTION_TYPES.LeaderElectionRepository)
  private leaderElection: ILeaderElectionRepository;

  @inject(APP_LOGGER_TYPES.AppLogger)
  private logger: IAppLogger;

  private dbChannel = new BroadcastChannel(DB_NAME);

  private taskScheduler = new TaskScheduler();

  private _db$: BehaviorSubject<Database | null> = new BehaviorSubject(null);

  private _status$: BehaviorSubject<DbStatus> = new BehaviorSubject(DbStatus.Destroyed);

  private get db(): Database | null {
    return this._db$.getValue();
  }

  private readonly _registeredCollectionCreators: Map<
    CollectionName,
    IDbCollectionCreator<unknown>
  > = new Map();

  private readonly _registeredStateCreators: Map<string, IDbStateCreator<object>> =
    new Map();

  constructor(
    @multiInject('DbCollectionCreator')
    registeredCollectionCreators: IDbCollectionCreator<unknown>[],
    @multiInject('DbStateCreator')
    registeredStateCreators: IDbStateCreator<object>[],
  ) {
    registeredCollectionCreators.forEach((item) => {
      this._registeredCollectionCreators.set(item.collectionName, item);
    });
    registeredStateCreators.forEach((item) => {
      this._registeredStateCreators.set(item.stateName, item);
    });

    this.dbChannel.onmessage = (message): void => {
      if (message === DbMessage.ReloadPage) {
        window.location.reload();
      }
    };
  }

  @postConstruct()
  logStatus(): void {
    this._status$.subscribe((status) => {
      this.logger.log(`[Database]: ${status}`);
    });
  }

  private initCollections(db: Database): Promise<DatabaseCollections> {
    const creatorConfigs: Record<
      CollectionName,
      RxCollectionCreator<unknown>
    > = {} as Record<CollectionName, RxCollectionCreator<unknown>>;

    for (const [
      collectionName,
      creator,
    ] of this._registeredCollectionCreators.entries()) {
      creatorConfigs[collectionName] = creator.getConfiguration();
    }

    return db.addCollections(creatorConfigs);
  }

  private initStates(db: Database): Promise<RxState<object>[]> {
    return Promise.all(
      Array.from(this._registeredStateCreators.values()).map((item) => item.create(db)),
    );
  }

  private applyCollectionHooks(
    collectionName: CollectionName,
    collection: RxCollection,
  ): void {
    const collectionCreator = this._registeredCollectionCreators.get(collectionName);

    if (!collectionCreator) {
      throw new DbError(
        `Trying to apply collection hooks to colleaction but  Collection creator for ${collectionName} is not registered`,
      );
    }

    collectionCreator.applyHooks(collection);
  }

  public getDb(): Observable<Database> {
    return this._db$.pipe(filter((db): db is Database => !!db));
  }

  public getStatus(): Observable<DbStatus> {
    return this._status$.pipe(share());
  }

  public async init(params?: { withMigration?: boolean }): Promise<void> {
    return this.taskScheduler.runTask(async () => {
      try {
        if (this.db) {
          this.logger.log('Database is already initialized');
          return;
        }

        const shouldRunMigration = params?.withMigration ?? false;

        this._status$.next(DbStatus.Initializing);

        const db = await createRxDatabase<DatabaseCollections>({
          name: DB_NAME,
          storage: getRxStorageDexie(),
        });

        const collections: Record<CollectionName, RxCollection> =
          await this.initCollections(db);

        for (const [collectionName, collection] of Object.entries(collections)) {
          this.applyCollectionHooks(collectionName as CollectionName, collection);
        }

        await this.initStates(db);

        if (shouldRunMigration) {
          await Promise.all(
            Object.values(collections).map(async (collection) => {
              const isNeeded = await collection.migrationNeeded();

              if (isNeeded) {
                return collection.migratePromise(10);
              }
            }),
          );
        }

        this._db$.next(db);
        this._status$.next(DbStatus.Ready);

        return db;
      } catch (e) {
        this.logger.error(e);
        await this.hardAppReset();
      }
    });
  }

  private async hardAppReset(): Promise<void> {
    localStorage.clear();
    const dbsNames = await Dexie.getDatabaseNames();

    await Promise.allSettled(dbsNames.map((db) => window.indexedDB.deleteDatabase(db)));

    this.dbChannel.postMessage(DbMessage.ReloadPage);

    window.location.reload();
  }

  private forceRemove(): Promise<unknown> {
    return removeRxDatabase(DB_NAME, getRxStorageDexie());
  }

  private async remove(): Promise<void> {
    this._status$.next(DbStatus.Removing);

    try {
      if (!this.db) {
        throw new Error('Database is not initialized');
      }

      await this.db.remove();
    } catch (e) {
      await this.forceRemove();
    } finally {
      this._db$.next(null);
      this._status$.next(DbStatus.Removed);
    }
  }

  public async clear(params?: { reloadOtherTabs?: boolean }): Promise<void> {
    await this.taskScheduler.runTask(async () => {
      if (this.leaderElection.isLeader) {
        await navigator.locks.request(
          'db-remove',
          { ifAvailable: true, mode: 'exclusive' },
          async (lock) => {
            if (!lock) {
              return;
            }

            try {
              await this.remove();
              params?.reloadOtherTabs && this.dbChannel.postMessage(DbMessage.ReloadPage);
            } catch (e) {
              this.logger.error(e);
              await this.hardAppReset();
            }
          },
        );
      }

      await this.init();
    });
  }
}
