import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import { firstValueFrom } from 'rxjs';

import { ROUTES } from '@/router/routes';

import {
  BillingError,
  PaymentError,
  useBillingUseCase,
  usePaymentCard,
} from '@/features/common/billing';
import { useSubscriptionUseCase } from '@/features/common/workspace';
import { FbPixelEvent, fbPixelTrackEvent } from '@/features/system/FbPixel';
import { useAppLogger } from '@/features/system/logger';
import { NetworkError } from '@/features/system/network';

import { useDocumentMeta } from '@/hooks';

import { PromotionExpiredError } from '../domain/errors/PromotionExpiredError';

import { PaymentDetailsContext, PaymentFormValues } from './contexts';
import {
  PaymentFailedReason,
  useCanBuy,
  usePaymentDetailsAnalytic,
  usePaymentErrorSnackbar,
  usePaymentSummary,
} from './hooks';

export interface IPaymentDetailsViewModel {
  summary: ReturnType<typeof usePaymentSummary>;
  isProcessing: boolean;
  onSubmit: () => Promise<void>;
}

export const usePaymentDetailsViewModel = (): IPaymentDetailsViewModel => {
  useDocumentMeta({
    title: 'payment.title',
    description: 'payment.description',
  });

  const { t } = useTranslation('paymentDetails');

  const { targetProduct } = useContext(PaymentDetailsContext);
  const form = useFormContext<PaymentFormValues>();

  const navigate = useNavigate();

  const stripe = useStripe();

  const analytic = usePaymentDetailsAnalytic();

  useEffect(() => {
    analytic.pageViewed();
  }, []);

  const billingUseCase = useBillingUseCase();
  const subscriptionUseCase = useSubscriptionUseCase();

  const logger = useAppLogger();

  const summary = usePaymentSummary();

  const canBuy = useCanBuy();

  const errorSnackbar = usePaymentErrorSnackbar();

  const paymentMethod = form.watch('paymentMethod');

  const shouldValidateCard = paymentMethod == null;

  const { card, validate } = usePaymentCard({
    shouldValidate: form.formState.isSubmitted && shouldValidateCard,
    onError: () => {
      analytic.paymentFailed(PaymentFailedReason.CardDetails);
    },
  });

  const createSubscription = async (
    formValues: PaymentFormValues,
    card?: Nullable<StripeCardElement>,
  ): Promise<void> => {
    if (!stripe) {
      throw new PaymentError('We have issues with payment provider loading.');
    }

    if (summary.receipt.data.total < 0.5) {
      throw new PaymentError('Payment amount is too low. Monimum amount is $0.50');
    }

    if (!formValues.paymentMethod) {
      await validate();
    }

    await billingUseCase.updateBillingDetails(formValues.billingDetails);

    const { secret } = await subscriptionUseCase.create({
      plan: targetProduct.id,
      billingDetailsFilled: true,
      promoCode: formValues.promotionCode,
      quantity: formValues.quantity,
    });

    if (secret) {
      const { error } = await stripe.confirmCardPayment(secret, {
        // @ts-ignore
        payment_method: formValues.paymentMethod?.id ?? {
          card: card,
          billing_details: {
            name: formValues.billingDetails.name,
          },
        },
        setup_future_usage: 'off_session',
        save_payment_method: true,
      });

      if (error) {
        throw PaymentError.fromStripeError(error);
      }
    }

    // try {
    await subscriptionUseCase.confirmPlanChange(targetProduct.id);
    // await subscriptionUseCase.update({
    //   plan: targetProduct.id,
    //   billingDetailsFilled: true,
    //   promoCode: formValues.promotionCode,
    //   quantity: formValues.seats,
    // });
    // } catch (error) {
    //   logger.error(error);
    // }

    analytic.paymentSuccess({
      planType: targetProduct.family,
      billingCycle: targetProduct.cycle,
    });

    fbPixelTrackEvent({
      type: FbPixelEvent.Purchase,
      options: { value: summary.receipt.data.total.toString(), currency: 'USD' },
    });

    navigate(ROUTES.SETTINGS.SUBSCRIPTION, { state: { justSubscribed: true } });
  };

  const updateSubscription = async (formValues: PaymentFormValues): Promise<void> => {
    await billingUseCase.updateBillingDetails(formValues.billingDetails);

    const { secret, paid } = await subscriptionUseCase.update({
      plan: targetProduct.id,
      billingDetailsFilled: true,
      promoCode: formValues.promotionCode,
      quantity: formValues.seats,
    });

    if (!paid) {
      if (!stripe) {
        throw new PaymentError('We have issues with payment provider loading.');
      }

      if (!secret) {
        throw new PaymentError('Client secret is missing');
      }

      const { error } = await stripe.confirmCardPayment(secret, {
        // @ts-ignore
        payment_method: formValues.paymentMethod?.id ?? {
          card: card,
          billing_details: {
            name: formValues.billingDetails.name,
          },
        },
        setup_future_usage: 'off_session',
        save_payment_method: true,
      });

      if (error) {
        throw PaymentError.fromStripeError(error);
      }
    }

    await subscriptionUseCase.confirmPlanChange(targetProduct.id);

    analytic.paymentSuccess({
      planType: targetProduct.family,
      billingCycle: targetProduct.cycle,
    });

    navigate(ROUTES.SETTINGS.SUBSCRIPTION);
  };

  const handlePaymentError = (error: unknown): void => {
    logger.error(error);

    switch (true) {
      case error instanceof PromotionExpiredError:
        summary.onPromotionCodeRemove();
        errorSnackbar.show(t('promotionCode.expired'));
        break;
      case error instanceof NetworkError:
        errorSnackbar.show(error.response?.data?.message ?? error.message);
        break;
      case error instanceof PaymentError:
      case error instanceof BillingError:
      case error instanceof Error:
        errorSnackbar.show(error.message);
        break;
      default:
        errorSnackbar.show();
        break;
    }

    analytic.paymentFailed(PaymentFailedReason.ServerError);
  };

  const handleSubmit = form.handleSubmit(
    async (data) => {
      try {
        const allowedToBuy = await canBuy();

        if (!allowedToBuy) throw new Error('You can not buy this product');

        const isUpgradable = await firstValueFrom(subscriptionUseCase.isUpgradable());

        if (isUpgradable) {
          await updateSubscription(data);
        } else {
          await createSubscription(data, card);
        }
      } catch (error) {
        handlePaymentError(error);
      }
    },
    async () => {
      if (!form.formState.isValid) {
        analytic.paymentFailed(PaymentFailedReason.EmptyFields);
      }
    },
  );

  return {
    summary,
    isProcessing: form.formState.isSubmitting,
    onSubmit: handleSubmit,
  };
};
