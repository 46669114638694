import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Alert, Box, Button, Skeleton, Typography } from '@mui/material';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { IPaymentDetailsViewModel } from '../../PaymentDetailsViewModel';

import { PromotionCode, SummaryPrice, SummaryRow, SummaryText } from './components';

import styles from './styles.module.scss';

type PaymentSummaryProps = Pick<IPaymentDetailsViewModel, 'summary' | 'isProcessing'>;

export const PaymentSummary: FC<PaymentSummaryProps> = ({ summary, isProcessing }) => {
  const { t: tSummary } = useTranslation('paymentDetails', { keyPrefix: 'summary' });
  const [hasLoadedOnce, setHasLoadedOnce] = useState(() => summary.receipt.isLoaded);

  useEffect(() => {
    if (summary.receipt.isLoaded) {
      setHasLoadedOnce(true);
    }
  }, [summary.receipt.isLoaded]);

  if (summary.receipt.hasError) {
    return (
      <Box className={styles.summaryCard}>
        <Typography variant="h2" className={styles.summaryTitle}>
          {tSummary('title')}
        </Typography>

        <Alert severity="error">Reciept loading error</Alert>
      </Box>
    );
  }

  if (summary.receipt.isLoading && !hasLoadedOnce) {
    return (
      <Box className={styles.summaryCard}>
        <Typography variant="h2" className={styles.summaryTitle}>
          {tSummary('title')}
        </Typography>

        <Skeleton height="65px" />
        <Box mb={'20px'} />
        <Skeleton height="25px" />
        <Box mb={'20px'} />
        <Skeleton height="55px" />
        <Box mb={'20px'} />
        <div className={styles.summaryDelimter} />
        <Skeleton height="28px" />
        <Box mb={'20px'} />

        <Button variant="contained" color="primary" fullWidth disabled>
          {tSummary('subscribe')}
        </Button>
        <Box mb={'20px'} />
        <Typography className={styles.summaryNotice}>
          <Trans
            i18nKey={'privacyAndPolicy'}
            t={tSummary}
            values={{
              period: tSummary(`${summary.billingCycle}Renew`),
            }}
            components={{
              a1: <a href={EXTERNAL_ROUTES.TERMS} target="_blank" rel="noreferrer" />,
              a2: <a href={EXTERNAL_ROUTES.PRIVACY} target="_blank" rel="noreferrer" />,
            }}
          >
            {'By continuing, you agree to Powerlead '}
            <a href={EXTERNAL_ROUTES.TERMS} target="_blank" rel="noreferrer">
              Terms of Use
            </a>
            {' and '}
            <a href={EXTERNAL_ROUTES.PRIVACY} target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
            .{' '}
            {
              'Your payment will be {{period}} auto-renewed until you\ncancel your subscription.'
            }
          </Trans>
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={styles.summaryCard}>
      <Typography variant="h2" className={styles.summaryTitle}>
        {tSummary('title')}
      </Typography>
      <SummaryRow>
        <SummaryText
          text={summary.receipt.data.product?.name ?? ''}
          notice={summary.receipt.data.product?.details ?? ''}
        />
        <SummaryPrice price={summary.receipt.data.product?.priceFormatted ?? ''} />
      </SummaryRow>
      {summary.receipt.data.adjustments.map((d) => {
        return (
          <SummaryRow key={d.name}>
            <SummaryText text={d.name} />
            <SummaryPrice price={d.amount} />
          </SummaryRow>
        );
      })}
      {summary.promotionsAvailable ? (
        <PromotionCode
          onApply={summary.onPromotionCodeApply}
          onRemove={summary.onPromotionCodeRemove}
          promotionCode={summary.receipt.data.promotion?.name}
        />
      ) : null}
      <div className={styles.summaryDelimter} />
      <SummaryRow className={styles.total}>
        <SummaryText text={tSummary('total', { date: summary.receipt.data.dueDate })} />
        <SummaryPrice price={summary.receipt.data.totalFormatted} isTotal />
      </SummaryRow>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled={isProcessing}
        type="submit"
        form="payment-details"
      >
        {tSummary('subscribe')}
      </Button>
      <Box mb={'20px'} />
      <Typography className={styles.summaryNotice}>
        <Trans
          i18nKey={'privacyAndPolicy'}
          t={tSummary}
          values={{
            period: tSummary(`${summary.billingCycle}Renew`),
          }}
          components={{
            a1: <a href={EXTERNAL_ROUTES.TERMS} target="_blank" rel="noreferrer" />,
            a2: <a href={EXTERNAL_ROUTES.PRIVACY} target="_blank" rel="noreferrer" />,
          }}
        >
          {'By continuing, you agree to Powerlead '}
          <a href={EXTERNAL_ROUTES.TERMS} target="_blank" rel="noreferrer">
            Terms of Use
          </a>
          {' and '}
          <a href={EXTERNAL_ROUTES.PRIVACY} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          .{' '}
          {
            'Your payment will be {{period}} auto-renewed until you\ncancel your subscription.'
          }
        </Trans>
      </Typography>
    </Box>
  );
};
