type WindowWithDataLayer = Window & {
  dataLayer?: Record<string, unknown>[];
};

declare const window: WindowWithDataLayer;

export const trackGtmEvent = (
  options: {
    event: string;
  } & {
    [key: string]: unknown;
  },
): void => {
  window.dataLayer?.push(options);
};
