import { inject, injectable } from 'inversify';
import {
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  race,
  switchMap,
  throwError,
  timer,
} from 'rxjs';

import { BILLING_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import type {
  BillingCycle,
  IBillingRepository,
  IProductEntity,
  PlanType,
} from '@/features/common/billing';

import type { IWorkspaceRepository } from '../data/abstractions/WorksapceRepository';

import type { SubscriptionPlan } from './types/SubscriptionPlan';
import type { ISubscriptionUseCase } from './abstractions';
import type { IWorkspaceSubscriptionEntity } from './entities';

@injectable()
export class SubscriptionUseCase implements ISubscriptionUseCase {
  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workpsaceRepository: IWorkspaceRepository;

  @inject(BILLING_TYPES.BillingRepository)
  private billingRepository: IBillingRepository;

  getSubscription(): Observable<IWorkspaceSubscriptionEntity> {
    return this.workpsaceRepository.getCurrentWorkspaceSubscription();
  }

  create(params: {
    plan: SubscriptionPlan;
    billingDetailsFilled?: boolean;
    quantity?: number;
    promoCode?: string;
  }): Promise<{ secret?: string }> {
    return this.workpsaceRepository.createSubscription(params);
  }

  async update(subscription: {
    plan?: SubscriptionPlan;
    isCanceled?: boolean;
    billingDetailsFilled?: boolean;
    quantity?: number;
    promoCode?: string;
  }): Promise<{
    secret?: string;
    paid: boolean;
  }> {
    return this.workpsaceRepository.updateSubscription(subscription);
  }

  private async waitWithTimeout<T>(
    observable: Observable<T>,
    timeout: number,
  ): Promise<T> {
    return firstValueFrom(
      race([
        observable,
        timer(timeout).pipe(switchMap(() => throwError(() => new Error('Timeout')))),
      ]),
    );
  }

  async cancel(): Promise<void> {
    await this.update({ isCanceled: true });
    await this.waitWithTimeout(
      this.getSubscription().pipe(filter((subsription) => subsription.isCanceled)),
      10_000,
    );
  }

  async confirmPlanChange(plan: string): Promise<void> {
    await firstValueFrom(
      this.getSubscription().pipe(filter((subscription) => subscription.plan === plan)),
    );
  }

  async updatePlan(plan: SubscriptionPlan): Promise<void> {
    await this.update({ plan, billingDetailsFilled: true });

    await this.waitWithTimeout(
      this.getSubscription().pipe(filter((subsription) => subsription.plan === plan)),
      10_000,
    );
  }

  async renew(): Promise<void> {
    await this.update({ isCanceled: false });

    await this.waitWithTimeout(
      this.getSubscription().pipe(filter((subsription) => !subsription.isCanceled)),
      10_000,
    );
  }

  getSubscriptionPlan(): Observable<string> {
    return this.getSubscription().pipe(
      map((subscription) => subscription.plan),
      distinctUntilChanged(),
    );
  }

  getSubscriptionPlanType(): Observable<PlanType> {
    return this.getSubscription().pipe(
      map((subscription) => subscription.planType),
      distinctUntilChanged(),
    );
  }

  getSubscriptionBillingCycle(): Observable<BillingCycle> {
    return this.getSubscription().pipe(
      map((subscription) => subscription.billingCycle),
      distinctUntilChanged(),
    );
  }

  getIsFreePlan(): Observable<boolean> {
    return this.getSubscription().pipe(
      map((subscription) => subscription.planIsFree),
      distinctUntilChanged(),
    );
  }

  getIsUnlimitedPlan(): Observable<boolean> {
    return this.getSubscription().pipe(
      map((subscription) => subscription.planIsUnlimited),
      distinctUntilChanged(),
    );
  }

  getIsCustomPlan(): Observable<boolean> {
    return this.getSubscription().pipe(map((subscription) => subscription.planIsCustom));
  }

  getPlanName(params: { variant?: 'short' | 'long' }): Observable<string> {
    return this.getSubscription().pipe(
      map((subscription) => {
        const planName = subscription.planName ?? 'Free';

        if (params?.variant === 'long') {
          const billingCycle = `${subscription.billingCycle.charAt(0)}${subscription.billingCycle.slice(1)}`;
          return `${planName} ${billingCycle}`;
        }

        return planName;
      }),
      distinctUntilChanged(),
    );
  }

  getNumberOfPaidSeats(): Observable<number> {
    return this.getSubscription().pipe(
      map((subscription) => subscription.paidMembersCount ?? 1),
      distinctUntilChanged(),
    );
  }

  isUpgradable(): Observable<boolean> {
    return this.getSubscription().pipe(
      map((sub) => !!sub.stripeCustomerId && sub.isActive),
      distinctUntilChanged(),
    );
  }

  isUpgrade(
    nextProduct: IProductEntity,
    currentProduct?: IProductEntity,
  ): Observable<boolean> {
    if (currentProduct) {
      return of(SubscriptionUseCase.isProductUpgrade({ nextProduct, currentProduct }));
    }

    return this.getSubscriptionPlan().pipe(
      switchMap((currentPlan) => this.billingRepository.getProduct(currentPlan)),
      map((currentProduct) =>
        SubscriptionUseCase.isProductUpgrade({
          nextProduct,
          currentProduct,
        }),
      ),
    );
  }

  private static isProductUpgrade(params: {
    nextProduct: IProductEntity;
    currentProduct: IProductEntity;
  }): boolean {
    const { nextProduct, currentProduct } = params;

    const nextProductTier = SubscriptionUseCase.getProductFamilyTier(nextProduct.family);
    const currentProductTier = SubscriptionUseCase.getProductFamilyTier(
      currentProduct.family,
    );

    if (nextProductTier > currentProductTier) return true;
    if (nextProductTier < currentProductTier) return false;

    return (
      SubscriptionUseCase.getCycleTire(nextProduct.cycle) >=
      SubscriptionUseCase.getCycleTire(currentProduct.cycle)
    );
  }

  private static getProductFamilyTier(family: IProductEntity['family']): number {
    switch (family) {
      case 'pro':
        return 1;
      case 'unlimited':
        return 2;
      case 'custom':
        return 3;
      case 'free':
      default:
        return 0;
    }
  }

  private static getCycleTire(cycle: IProductEntity['cycle'] | BillingCycle): number {
    switch (cycle) {
      case 'monthly':
        return 1;
      case 'annually':
        return 2;
      case 'daily':
      default:
        return 0;
    }
  }
}
