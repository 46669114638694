import { inject, injectable } from 'inversify';

import { ANALYTICS_TYPES } from '@/ioc/types';

import { type IAnalyticsRepository } from './abstractions/IAnalyticsRepository';
import { type IAnalyticsUseCase } from './abstractions/IAnalyticsUseCase';

@injectable()
export class AnalyticsUseCase implements IAnalyticsUseCase {
  @inject(ANALYTICS_TYPES.AnalyticsRepository)
  private analyticsRepository: IAnalyticsRepository;

  public trackEvent(eventType: string, eventOptions?: Record<string, unknown>): void {
    return this.analyticsRepository.trackEvent(eventType, eventOptions);
  }
}
