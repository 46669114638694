import { FC, PropsWithChildren, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { clsx } from 'clsx';

import { ProductPrice } from '@/features/plans/domain';
import { BillingCycleContext, SeatsContext } from '@/features/plans/ui/contexts';

import styles from './styles.module.scss';

const PriceBox: FC<PropsWithChildren> = ({ children }) => {
  return <Box className={styles.priceContainer}>{children}</Box>;
};

const Price: FC<{ title: string; crossed?: string; custom?: boolean }> = ({
  title,
  custom,
}) => {
  return (
    <Typography
      className={clsx(styles.priceTitle, { [styles.priceTitleCustom]: custom })}
    >
      {title}
    </Typography>
  );
};

const PriceCrossed: FC<{ crossed: string }> = ({ crossed }) => {
  return <Typography className={styles.priceCrossed}>{crossed}</Typography>;
};

const PriceDescription: FC<{ description: string }> = ({ description }) => {
  return <Typography className={styles.priceDescription}>{description}</Typography>;
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

export const PlanPrice: FC<{ perSeat: ProductPrice; total?: number }> = ({
  perSeat,
  total,
}) => {
  const { t } = useTranslation('plans', { keyPrefix: 'prices' });

  const seats = useContext(SeatsContext);
  const cycle = useContext(BillingCycleContext);

  if (typeof perSeat === 'number' && perSeat === 0) {
    return (
      <PriceBox>
        <Price title={formatter.format(perSeat)} />
        <PriceDescription description={t('freeSubtitle')} />
      </PriceBox>
    );
  }

  if (typeof perSeat === 'number') {
    return (
      <PriceBox>
        <Price title={formatter.format(perSeat)} />
        <PriceDescription
          description={t('totalPricePerMonth', {
            price: formatter.format(total ?? 0),
          })}
        />
        <PriceDescription description={t(`billed${cycle}`, { count: seats })} />
      </PriceBox>
    );
  }

  if (typeof perSeat === 'object') {
    return (
      <PriceBox>
        <Stack direction="row" alignItems="baseline" gap="5px">
          <Price title={formatter.format(perSeat.price)} />
          <PriceCrossed crossed={formatter.format(perSeat.crossed)} />
        </Stack>
        <PriceDescription
          description={t('totalPricePerMonth', {
            price: formatter.format(total ?? 0),
          })}
        />
        <PriceDescription description={t(`billed${cycle}`, { count: seats })} />
      </PriceBox>
    );
  }

  if (perSeat === 'contact-sales') {
    return (
      <PriceBox>
        <Price title={t('contactTitle')} custom />
        <PriceDescription description={t('contactSubtitle')} />
      </PriceBox>
    );
  }

  return null;
};
