import { useTranslation } from 'react-i18next';
import { filter, map } from 'rxjs';

import { useInjection } from '@/ioc';
import { WORKSPACE_TYPES } from '@/ioc/types';

import type { IWorkspaceUseCase } from '@/features/common/workspace';

import { useObservableResult } from '@/utils/rx';

export const useTeamMembersCardViewModel = () => {
  const workspaceUseCase = useInjection<IWorkspaceUseCase>(
    WORKSPACE_TYPES.WorkspaceUseCase,
  );
  const { t } = useTranslation('dashboard', { keyPrefix: 'teamMembersCard' });

  const formatSeats = (seats: { limit: number; count: number }): string => {
    if (seats.limit === 0 || seats.count === seats.limit) {
      return t('seats.count', { count: seats.count });
    }

    return t('seats.countRange', { used: seats.count, limit: seats.limit });
  };

  const seats = useObservableResult(
    () =>
      workspaceUseCase.getCurrentWorkspace().pipe(
        filter((workspace) => !!workspace),
        map((workspace) => {
          return {
            seats: {
              count: workspace.billableMembersCount,
              limit:
                workspace.subscription.planPaidMembersLimit ||
                workspace.subscription.paidMembersCount ||
                0,
            },
          };
        }),
        map(({ seats }) => {
          return formatSeats(seats);
        }),
      ),
    {
      defaultData: '',
    },
  );

  return {
    seats,
  };
};
