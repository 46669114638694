import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';

import { CallToAction } from '@/features/callToAction';
import {
  ActionDisableGuard,
  HasPermission,
  Permission,
} from '@/features/common/permissions';

import TeamMembersPreview from '@/assets/icons/teamMembersPreview.svg?react';

import { useTeamMembersCardViewModel } from './TeamMembersCardViewModel';

import styles from './styles.module.scss';

export const TeamMembersCard: FC = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'teamMembersCard' });
  const { seats } = useTeamMembersCardViewModel();

  if (!seats.isLoaded) {
    return <TeamMembersPreview preserveAspectRatio="none" width="402px" height="131px" />;
  }

  return (
    <Stack className={styles.wrapper} width="402px" height="131px">
      <Typography variant="h1">{t('title')}</Typography>
      <Box mb={0.75} />
      <Typography variant="h3">{seats.data}</Typography>
      <Box mb={2} />
      <HasPermission permission={Permission.CanManageMembers}>
        <ActionDisableGuard>
          <CallToAction
            show="inviteTeamMember"
            variant="outlined"
            size="small"
            withIcon={false}
            force
          />
        </ActionDisableGuard>
      </HasPermission>
    </Stack>
  );
};
