import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { ErrorPage500 } from '@/router/error';
import { EXTERNAL_ROUTES } from '@/router/routes';

import { PlanDowngradeDialog, ReduceActiveUsersDialog } from '@/features/common/billing';

import { SplashScreen } from '@/components';

import { PlanActionResolverContext } from './contexts/PlanActionResolverContext';
import {
  BillingCycleDialog,
  ComparePlansHeader,
  ComparePlansTable,
  CustomerReviews,
  MonthlyYearlySwitcher,
  PlanCard,
  PlansFAQ,
  PlansList,
  PlansPageLayout,
  UsersCounter,
} from './components';
import {
  BillingCycleContext,
  PlanFeaturesMetadataContext,
  PlansMetadataContext,
  SeatsContext,
} from './contexts';
import { usePlansViewModel } from './usePlansViewModel';

import styles from './Plans.module.scss';

export const Plans: FC = () => {
  const {
    planTypesToShow,
    planFeatureCategoriesToShow,
    billingCycleDialog,
    reduceActiveUsersDialog,
    planDowngradeDialog,
    planActionResolver,
    newPlansMetadataResult,
    pageDataResult,
    seats,
    billingCycle,
  } = usePlansViewModel();
  const { t } = useTranslation('plans');

  if (newPlansMetadataResult.isLoading || pageDataResult.isLoading) {
    return <SplashScreen />;
  }

  if (newPlansMetadataResult.hasError || pageDataResult.hasError) {
    return <ErrorPage500 />;
  }

  const { plansMetadata, plansFeatureMetadata } = newPlansMetadataResult.data;

  return (
    <PlanActionResolverContext.Provider value={planActionResolver}>
      <SeatsContext.Provider value={seats.value}>
        <BillingCycleContext.Provider value={billingCycle.value}>
          <PlansMetadataContext.Provider value={plansMetadata}>
            <PlanFeaturesMetadataContext.Provider value={plansFeatureMetadata}>
              <PlansPageLayout>
                <section className={styles.plansAndPricing}>
                  <Typography variant="h1" className={styles.plansAndPricingTitle}>
                    {t('title')}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={styles.plansAndPricingSubtitle}
                  >
                    {t('subtitle')}
                  </Typography>
                  <Box className={styles.switchers}>
                    <UsersCounter value={seats.value} onChange={seats.onChange} />
                    <MonthlyYearlySwitcher
                      value={billingCycle.value}
                      onChange={billingCycle.onChange}
                      annualDiscount={pageDataResult.data.maxAnnaulDiscount}
                    />
                  </Box>
                  <PlansList>
                    {planTypesToShow.map((planType) => (
                      <PlanCard key={planType} variant={planType} />
                    ))}
                  </PlansList>
                </section>
                <section className={styles.comparePlans}>
                  <Typography variant="h2" className={styles.title}>
                    {t('comparePlans.title')}
                  </Typography>
                  <Typography variant="subtitle1" className={styles.subtitle}>
                    {t('comparePlans.subtitle')}
                  </Typography>
                  <ComparePlansHeader />
                  {planFeatureCategoriesToShow.map((planFeatureCategory) => (
                    <ComparePlansTable
                      key={planFeatureCategory}
                      planTypes={planTypesToShow}
                      planFeatureCategory={planFeatureCategory}
                    />
                  ))}
                </section>
                <section className={styles.plansFAQ}>
                  <Typography variant="h2" className={styles.title}>
                    {t('faq.title')}
                  </Typography>

                  <Typography variant="subtitle1" className={styles.plansFAQSubtitle}>
                    <Trans
                      i18nKey="faq.subtitle"
                      t={t}
                      components={{
                        a: <a href={EXTERNAL_ROUTES.HELP_CENTER} target="_blank" />,
                      }}
                    />
                  </Typography>

                  <PlansFAQ />
                </section>
                <section className={styles.customerReviewsWrapper}>
                  <div className={styles.customerReviews}>
                    <Typography variant="h2" className={styles.customerReviewsTitle}>
                      {t('customerReviews.title')}
                    </Typography>
                    <Typography
                      variant="h2"
                      className={styles.customerReviewsTitlePhones}
                    >
                      {t('customerReviews.titlePhones')}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={styles.customerReviewsSubtitle}
                    >
                      {t('customerReviews.subtitle')}
                    </Typography>
                    <CustomerReviews />
                  </div>
                </section>
                <BillingCycleDialog {...billingCycleDialog} />
                <PlanDowngradeDialog {...planDowngradeDialog} />
                <ReduceActiveUsersDialog {...reduceActiveUsersDialog} />
              </PlansPageLayout>
            </PlanFeaturesMetadataContext.Provider>
          </PlansMetadataContext.Provider>
        </BillingCycleContext.Provider>
      </SeatsContext.Provider>
    </PlanActionResolverContext.Provider>
  );
};
