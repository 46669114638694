import { useEffect } from 'react';

import { useAnalyticsUseCase } from './hooks';

export const useAnalytics = (
  event?: string,
  eventProperties?: Record<string, unknown>,
): {
  trackEvent: (event: string, eventProperties?: Record<string, unknown>) => void;
} => {
  const analyticUseCase = useAnalyticsUseCase();

  useEffect(() => {
    if (event) {
      trackEvent(event, eventProperties);
    }
  }, []);

  const trackEvent = (event, eventProperties?) =>
    analyticUseCase.trackEvent(event, eventProperties);

  return {
    trackEvent,
  };
};
